import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import Grid from "@material-ui/core/Grid"
import Title from "../title"
import Button from "../button"
import "./index.scss"

const WhyUsSection = ({ data }) => {
  const { content, fieldGroupName, image, title, button } = data
  // console.log(button)
  const imageSource = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "squares.png" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <section className="why-us-section">
      <div className="why-us-section-wrapper">
        <div className="container">
          <Grid container alignItems="center">
            <Grid item md sm={12}>
              <div className="why-us-section__left">
                <div className="why-us-section__left__title">
                  <Title title2={title || fieldGroupName} />
                </div>
                <p className="why-us-section__left__desc">{content}</p>
                <Button
                  to="/app/register"
                  styles="btn btn__round btn__round-dark"
                >
                  {button.text}
                </Button>
              </div>
            </Grid>
            <Grid item md xs={12}>
              <div data-aos="fade-left" className="why-us-section__right">
                {image ? (
                  <Img
                    fluid={image.localFile.childImageSharp.fluid}
                    alt={image.altText || "Image"}
                  />
                ) : (
                  <Img
                    fluid={imageSource.placeholderImage.childImageSharp.fluid}
                  />
                )}
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    </section>
  )
}

export default WhyUsSection
