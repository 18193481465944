import React from "react"
import Grid from "@material-ui/core/Grid"
import DashboardIcon from "@material-ui/icons/Dashboard"
import FlipCameraAndroidIcon from "@material-ui/icons/FlipCameraAndroid"
import FormatIndentIncreaseIcon from "@material-ui/icons/FormatIndentIncrease"
import Title from "../title"
import Button from "../button"
import "./index.scss"

const BenefictSection = ({ data }) => {
  const { content, fieldGroupName, columns, title } = data
  const { one, two, three } = columns
  return (
    <section className="benefits-section container">
      <div className="benefits-section__top">
        <Title title2={title || fieldGroupName} />
        <p className="benefits-section__top-desc">{content}</p>
      </div>
      <div className="benefits-section__cards">
        <Grid alignItems="center" justify="center" container>
          <Grid item md>
            <div data-aos="fade-up" className="benefits-section__cards__item">
              <div className="benefits-section__cards__item-logo">
                <DashboardIcon />
              </div>
              <h3>{one.header}</h3>
              <p>{one.content}</p>
            </div>
          </Grid>
          <Grid item md>
            <div data-aos="fade-up" className="benefits-section__cards__item">
              <div className="benefits-section__cards__item-logo">
                <FlipCameraAndroidIcon />
              </div>
              <h3>{two.header}</h3>
              <p>{two.content}</p>
            </div>
          </Grid>
          <Grid item md>
            <div data-aos="fade-up" className="benefits-section__cards__item">
              <div className="benefits-section__cards__item-logo">
                <FormatIndentIncreaseIcon />
              </div>
              <h3>{three.header}</h3>
              <p>{three.content}</p>
            </div>
          </Grid>
        </Grid>
      </div>
      <div className="benefits-section__button">
        <Button
          to="#"
          styles="btn btn__round btn__round-light btn__round-light-inverted"
        >
          Learn more
        </Button>
      </div>
    </section>
  )
}

export default BenefictSection
