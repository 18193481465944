import React, { useEffect } from "react"
import { graphql } from "gatsby"
import SEO from "../components/seo"
import ContactSection from "../components/contact/index"
import HeroSection from "../components/hero/index"
import CardsSection from "../components/cards-section"
import DeckSection from "../components/deck-section"
import WhyUsSection from "../components/why-us-section"
import BenefictSection from "../components/benefits-section"
import SubscriptionSection from "../components/subscription-plans"
import AOS from "aos"
import "aos/dist/aos.css"

export default ({ data }) => {
  // console.log()
  const {
    page: {
      nodes
    },
  } = data

  const {
    homepage: { section1, section2, section3, section4, section5, section6, pricingtable },
  } = nodes[0]

  useEffect(() => {
    AOS.init({
      duration: 1200,
    })
  }, [])

  return (
    <>
      <SEO title="Home" />
      <HeroSection data={section1} />
      <CardsSection data={section2} />
      <DeckSection data={section3} />
      <WhyUsSection data={section4} />
      <BenefictSection data={section5} />
      <SubscriptionSection data={section6} pricingtable={pricingtable} />
      <ContactSection />
    </>
  )
}

export const query = graphql`
  {
    page: allWpPage(filter: {title: {eq: "Home Page"}}) {
      nodes {
        homepage {
          section1 {
            content
            fieldGroupName
            title
            subheading
            image {
              altText
              localFile {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid_noBase64
                  }
                }
              }
            }
          }
          section2 {
            fieldGroupName
            title
            subtitle
            content
            card1 {
              title
              content
              image {
                altText
                localFile {
                  childImageSharp {
                    fixed(width: 52) {
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
            }
            card2 {
              title
              content
              image {
                altText
                localFile {
                  childImageSharp {
                    fixed(width: 52) {
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
            }
            card3 {
              title
              content
              image {
                altText
                localFile {
                  childImageSharp {
                    fixed(width: 52) {
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
            }
            card4 {
              title
              content
              image {
                altText
                localFile {
                  childImageSharp {
                    fixed(width: 52) {
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
            }
            card1Reverse {
              content
              title
              image {
                altText
                localFile {
                  childImageSharp {
                    fixed(width: 52) {
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
            }
            card2Reverse {
              content
              title
              image {
                altText
                localFile {
                  childImageSharp {
                    fixed(width: 52) {
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
            }
            card3Reverse {
              content
              title
              image {
                altText
                localFile {
                  childImageSharp {
                    fixed(width: 52) {
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
            }
            card4Reverse {
              content
              title
              image {
                altText
                localFile {
                  childImageSharp {
                    fixed(width: 52) {
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
            }
          }
          section3 {
            fieldGroupName
            title
            subheading
            content
            image {
              altText
              localFile {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
          section4 {
            fieldGroupName
            title
            content
            button {
              url
              text
            }
            image {
              altText
              localFile {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
          section5 {
            fieldGroupName
            title
            content
            columns {
              one {
                header
                content
              }
              three {
                header
                content
              }
              two {
                header
                content
              }
            }
          }
          section6 {
            fieldGroupName
            title
            content
          }
          pricingtable {
          title
            bronzeplan {
              buttontext
              feature1
              feature2
              feature3
              pricemontly
              fieldGroupName
              title
            }
            freeplan {
              buttontext
              feature1
              feature2
              feature3
              fieldGroupName
              title
            }
            goldplan {
              buttontext
              feature1
              pricemontly
              title
              feature3
              feature2
              fieldGroupName
            }
            silverplan {
              title
              pricemontly
              fieldGroupName
              feature3
              feature2
              feature1
              buttontext
            }
          }
        }
      }
    }
  }
`
