import React from "react"
import Grid from "@material-ui/core/Grid"
import CheckCircleIcon from "@material-ui/icons/CheckCircle"
import Title from "../title"
import Button from "../button"
import "./index.scss"

const SubscriptionSection = ({ data, pricingtable }) => {
  const { content, fieldGroupName, title } = data
  const { bronzeplan, freeplan, goldplan, silverplan } = pricingtable;

  return (
    <section className="subscription-section">
      <div className="container">
        <div className="subscription-section__top">
          <Title title2={title || fieldGroupName} />
          <p className="subscription-section__top-desc">{content}</p>
        </div>
        <h1 className="subscription-section__top__title">{pricingtable.title}</h1>
        <Grid container alignItems="center" justify="center">
          <Grid item xs={12} md={3}>
            <div data-aos="fade-right" className="subscription-section__left">
              <Grid container justify="center" alignItems="center">
                <Grid item md >
                  <div className="subscription-section__left__item">
                    <h3>{freeplan.title}</h3>
                    <ul className="subscription-section__left__item-ul">
                      <li>
                        <span>
                          <CheckCircleIcon />
                        </span>
                        {freeplan.feature1}
                      </li>
                      <li>
                        <span>
                          <CheckCircleIcon />
                        </span>
                        {freeplan.feature2}
                      </li>
                      <li>
                        <span>
                          <CheckCircleIcon />
                        </span>
                        {freeplan.feature3}
                      </li>
                    </ul>
                    <Button styles="btn btn__light">{freeplan.buttontext}</Button>
                  </div>
                </Grid>
              </Grid>
            </div>
          </Grid>
          <Grid item xs={12} md={9}>
            <div
              data-aos-delay="400"
              data-aos="fade-left"
              className="subscription-section__right"
            >
              <div className="subscription-section__right-title">
                <h3>BETTER FOR TRAINERS </h3>
              </div>
              <Grid container justify="center" alignItems="center">
                <div className="subscription-section__right__content">
                  <Grid item md>
                    <div className="subscription-section__right__content-item">
                      <h3 className="subscription-section__right__content-item__title">{bronzeplan.title}</h3>
                      <h1 className="subscription-section__right__content-item__price">
                        <span>{bronzeplan.pricemontly}</span>/mo.
                      </h1>
                      <ul>
                        <li>
                          <span>
                            <CheckCircleIcon />
                          </span>
                          {bronzeplan.feature1}
                        </li>
                        <li>
                          <span>
                            <CheckCircleIcon />
                          </span>
                          {bronzeplan.feature2}
                        </li>
                        <li>
                          <span>
                            <CheckCircleIcon />
                          </span>
                          {bronzeplan.feature3}
                        </li>
                      </ul>
                      <Button styles="btn btn__dark">{bronzeplan.buttontext}</Button>
                    </div>
                  </Grid>
                  <Grid item md>
                    <div className="subscription-section__right__content-item">
                      <h3 className="subscription-section__right__content-item__title">{silverplan.title}</h3>
                      <h1 className="subscription-section__right__content-item__price">
                        <span>{silverplan.pricemontly}</span>/mo.
                      </h1>
                      <ul>
                        <li>
                          <span>
                            <CheckCircleIcon />
                          </span>
                          {silverplan.feature1}
                        </li>
                        <li>
                          <span>
                            <CheckCircleIcon />
                          </span>
                          {silverplan.feature2}
                        </li>
                        <li>
                          <span>
                            <CheckCircleIcon />
                          </span>
                          {silverplan.feature3}
                        </li>
                      </ul>
                      <Button styles="btn btn__dark">{silverplan.buttontext}</Button>
                    </div>
                  </Grid>
                  <Grid item md>
                    <div className="subscription-section__right__content-item">
                      <h3 className="subscription-section__right__content-item__title">{goldplan.title}</h3>
                      <h1 className="subscription-section__right__content-item__price">
                        <span>{goldplan.pricemontly}</span>/mo.
                      </h1>
                      <ul>
                        <li>
                          <span>
                            <CheckCircleIcon />
                          </span>
                          {goldplan.feature1}
                        </li>
                        <li>
                          <span>
                            <CheckCircleIcon />
                          </span>
                          {goldplan.feature2}
                        </li>
                        <li>
                          <span>
                            <CheckCircleIcon />
                          </span>
                          {goldplan.feature3}
                        </li>
                      </ul>
                      <Button styles="btn btn__dark">{goldplan.buttontext}</Button>
                    </div>
                  </Grid>
                </div>
                <p>Want to find out more? Contact us below</p>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </div>
    </section>
  )
}

export default SubscriptionSection
