import React from "react"

const CardIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      version="1.1"
      x="0px"
      y="0px"
      viewBox="0 0 100 125"
      //style="enable-background:new 0 0 100 100;"
      xmlSpace="preserve"
    >
      <path d="M92,49l-6.9-8.2l2.3-5.7c1.7-4.1-0.3-8.8-4.4-10.4L53.7,12.6c-1.1-3.2-4.1-5.5-7.6-5.5h-32c-4.4,0-8,3.6-8,8v48  c0,4.4,3.6,8,8,8h13L42.8,90c1.4,1.6,3.3,2.6,5.4,2.8c0.2,0,0.5,0,0.7,0c1.9,0,3.7-0.6,5.1-1.9l7.8-6.5c1,0,2.1-0.2,3.1-0.6  c2-0.8,3.5-2.4,4.3-4.4l0.7-1.8L91,60.2c1.6-1.4,2.6-3.3,2.8-5.4C94,52.7,93.3,50.6,92,49z M54.2,45.2l4.2-2.4l2,7l-6.1,3.5V45.2z   M14.2,67.2c-2.2,0-4-1.8-4-4v-48c0-2.2,1.8-4,4-4h32c2.2,0,4,1.8,4,4v48c0,2.2-1.8,4-4,4H14.2z M51.5,87.9c-0.8,0.7-1.9,1-2.9,0.9  c-1.1-0.1-2-0.6-2.7-1.4L34.7,74l22.5,9.2L51.5,87.9z M65.6,77.9c-0.4,1-1.2,1.8-2.2,2.2c-0.5,0.2-1,0.3-1.6,0.3  c-0.5,0-1-0.1-1.5-0.3l-22-9h7.8c4.4,0,8-3.6,8-8V58l9.5-5.5c0.8-0.5,1.2-1.4,0.9-2.3l-3.1-11c-0.2-0.6-0.6-1.1-1.2-1.3  c-0.6-0.2-1.2-0.2-1.8,0.1l-4.4,2.5V17.1l27.4,11.1c2,0.8,3,3.2,2.2,5.2L65.6,77.9z M88.4,57.2L73.3,69.8l10.1-24.9l5.5,6.6  c0.7,0.8,1,1.9,0.9,2.9C89.7,55.5,89.2,56.5,88.4,57.2z M63.1,68.3L61.6,72c-0.3,0.8-1.1,1.2-1.9,1.2c-0.2,0-0.5,0-0.8-0.1  c-1-0.4-1.5-1.6-1.1-2.6l1.5-3.7c0.4-1,1.6-1.5,2.6-1.1C63,66.1,63.5,67.2,63.1,68.3z M20.2,19.2v4c0,1.1-0.9,2-2,2  c-1.1,0-2-0.9-2-2v-4c0-1.1,0.9-2,2-2C19.3,17.2,20.2,18.1,20.2,19.2z M44.2,55.2v4c0,1.1-0.9,2-2,2c-1.1,0-2-0.9-2-2v-4  c0-1.1,0.9-2,2-2C43.3,53.2,44.2,54.1,44.2,55.2z M34.2,31c-1.5-0.1-2.9,0.3-4.1,1c-1.2-0.7-2.6-1.1-4.1-1c-2.5,0.1-4.6,1.6-5.5,4  c-0.9,2.4-0.4,5,1.4,6.8l6.8,6.9c0.4,0.4,0.9,0.6,1.4,0.6c0.5,0,1.1-0.2,1.4-0.6l6.8-6.9c1.8-1.8,2.3-4.4,1.4-6.8  C38.8,32.7,36.7,31.2,34.2,31z M35.5,39l-5.3,5.5L24.8,39c-1-1-0.6-2.2-0.5-2.5c0.2-0.4,0.7-1.4,1.9-1.4h0.2c0.9,0,1.7,0.3,2.3,1  c0.8,0.8,2.1,0.8,2.9,0c0.6-0.6,1.4-1,2.3-1H34c1.3,0.1,1.8,1,1.9,1.4C36.1,36.8,36.5,38,35.5,39z" />
    </svg>
  )
}

export default CardIcon
