import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import Grid from "@material-ui/core/Grid"
import Button from "../button"
import Title from "../title"
import "./index.scss"

const HeroSection = ({ data }) => {
  const { content, fieldGroupName, image, title, subheading } = data

  const imageSource = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "card-decks.png" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <div className="hero-section container">
      <Grid justify="center" alignItems="center" container spacing={3}>
        <Grid item xs={12} sm={10} md={6}>
          <div data-aos="fade-right" className="hero-section__content">
            <Title title2={title || fieldGroupName} />
            <h2>{subheading}</h2>
            <p>{content}</p>
            <div className="hero-section__content-buttons">
              <Button to="/app/login/" styles="btn btn__round btn__round-light">
                start now
              </Button>
              <Button to="#contact" styles="btn btn__round btn__round-dark">
                talk to us
              </Button>
            </div>
          </div>
        </Grid>
        <Grid item xs={10} sm={8} md={6}>
          <div data-aos="fade-up" className="hero-section__image">
            {null !== image ? (
              <Img
                fluid={image.localFile.childImageSharp.fluid}
                alt={image.altText || "Image"}
              />
            ) : (
              <Img fluid={imageSource.placeholderImage.childImageSharp.fluid} />
            )}
          </div>
        </Grid>
      </Grid>
    </div>
  )
}

export default HeroSection
