import React from "react"
import SocialIcons from "../social-links"
import "./index.scss"

const Footer = () => {
  return (
    <footer className="footer">
      <SocialIcons />
      <hr />
      <p className="footer-copy">
        Chatdecks Demo Layout. / © {new Date().getFullYear()} /
      </p>
    </footer>
  )
}

export default Footer
