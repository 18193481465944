import React from "react"
import "./index.scss"

const Title = ({ title1, title2 }) => {
  return (
    <div>
      <h1 className="main-title">
        {title1 && <span>{title1} </span>}
        {title2}
      </h1>
    </div>
  )
}

export default Title
