import React from "react"
import TextField from "@material-ui/core/TextField"
import { withStyles } from "@material-ui/core/styles"
import Grid from "@material-ui/core/Grid"
import Footer from "../../components/footer/index"
import Button from "../button/index"
import "./index.scss"

const MyTextField = withStyles({
  root: {
    "& .MuiInputBase-root": {
      fontWeight: "500",
      fontFamily: "Overpass",
    },
    "& .MuiFilledInput-multiline": {
      padding: "0",
    },
    "& .MuiFormLabel-root": {
      fontWeight: "600",
      fontFamily: "Overpass",
      color: "#B3E2EA",
    },
    "& label.Mui-focused": {
      color: "#005F72",
    },
    "& .MuiFilledInput-root": {
      backgroundColor: "#ffffff",
      borderRadius: "8px",
    },
    "& .MuiFilledInput-input": {
      padding: "1.9rem 0.75rem 0.75rem",
    },
    "& .MuiFilledInput-underline:after": {
      display: "none",
    },
    "& .MuiFilledInput-underline:before": {
      display: "none",
    },
  },
  "@media (max-width: 960px)": {
    root: {
      marginTop: "0",
    },
  },
})(TextField)

const ContactSection = () => {
  return (
    <div id="contact" className="contact-section-wrapper">
      <div className="contact-bg">
        <div className="contact-bg__color"></div>
        <div className="contact-bg__shape-top"></div>
        <div className="contact-bg__shape-bottom"></div>
      </div>
      <section className="contact-section">
        <div className="container">
          <div data-aos="fade-up" className="form-wrapper">
            <h1>Contact Us</h1>
            <form>
              <Grid container spacing={3}>
                <Grid item md={6} xs={12}>
                  <MyTextField
                    variant="filled"
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Please enter your Email"
                    name="email"
                    autoComplete="email"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <MyTextField
                    variant="filled"
                    margin="normal"
                    required
                    fullWidth
                    id="name"
                    label="Please enter your Name"
                    name="name"
                    autoComplete="name"
                  />
                </Grid>
                <Grid item xs={12}>
                  <MyTextField
                    variant="filled"
                    margin="normal"
                    required
                    fullWidth
                    id="message"
                    label="Message"
                    name="message"
                    multiline
                    rows={6}
                    autoComplete="message"
                  />
                </Grid>
              </Grid>
              <div>
                <Button styles="btn__light">Send Message</Button>
              </div>
            </form>
            <Footer />
          </div>
        </div>
      </section>
    </div>
  )
}

export default ContactSection
