import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import Title from "../title"
import "./index.scss"

const DeckSection = ({ data }) => {
  const { content, fieldGroupName, image, title, subheading } = data

  const imageSource = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "card-decks2.png" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <section className="deck-section container">
      <div className="deck-section__title">
        <Title title2={title || fieldGroupName} />
        <h2>{subheading}</h2>
      </div>
      <p>{content}</p>
      <div data-aos="fade-up" className="deck-section__img">
        {image ? (
          <Img
            fluid={image.localFile.childImageSharp.fluid}
            alt={image.altText || "Image"}
          />
        ) : (
          <Img fluid={imageSource.placeholderImage.childImageSharp.fluid} />
        )}
      </div>
    </section>
  )
}

export default DeckSection
